import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import SEO from "../components/seo"
import Layout from "../components/layout"
import CompleetJourney from "../components/compleetJourney"

import "./vvkr.scss";

const pageQuery = graphql`
    {
        hyco {
            titles {
                indicator
                value
                richValue { html }
            }
        }
    }
`;

const VvkrPage = (queryData) => {
    const {
        hyco: { titles }
    } = useStaticQuery(pageQuery);

    let metaTitle = '';

    for (const title of titles) {
        if (title.indicator === 'compleet-title-meta-tag') {
            metaTitle = title.value;
        }
    }

    return (
        <Layout>
            <SEO title={metaTitle} urlParams="compleet" page="compleet" crawlable="false" />
            <img className="print-image" src='https://media.travelspirit.nl/original/sm/TravelSpirit_Logo-FC.png' alt="logo" loading="lazy" />
            <CompleetJourney />
        </Layout>
    )
}

export default VvkrPage
